import React, { Component } from "react"
import createAnimation from "../utils/create-animation"

class Animation extends Component {
  constructor() {
    super()
    this.scene = React.createRef()
    this.animation = null
  }

  state = {
    width: 0,
    height: 0,
    mobile: false,
  }

  componentDidMount() {
    this.setHeightWidth()
    this.setState({ mobile: window.innerWidth <= 750 })
    window.addEventListener("resize", this.setHeightWidth)
  }

  componentDidUpdate(props, newState) {
    if (newState.width !== this.state.width) {
      if (this.animation) this.animation.stop()
      this.animation = createAnimation(
        this.scene.current,
        this.state.width,
        this.state.height,
        this.state.mobile
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeightWidth)
  }

  setHeightWidth = () => {
    const width = window.innerWidth
    const height = window.innerHeight

    this.setState({ width, height })
  }

  render() {
    return <div ref={this.scene} />
  }
}

export default Animation
