import styled from "styled-components"

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: ${({ theme }) => theme.background};
  transition: all 0.3s ease-in-out;
`

export default Background
