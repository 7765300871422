import Matter from "matter-js"
import grapesXY from "./grapesXY"
if (typeof window !== `undefined`) {
  window.decomp = require("poly-decomp")
}

export default (element, width = 800, height = 800, mobile) => {
  const Engine = Matter.Engine
  const Render = Matter.Render
  const World = Matter.World
  const Bodies = Matter.Bodies
  const Vertices = Matter.Vertices
  const Mouse = Matter.Mouse
  const MouseConstraint = Matter.MouseConstraint
  const scale = mobile ? width / 850 : width / 1600
  const radius = scale * 123.6

  const engine = Engine.create()

  const render = Render.create({
    element,
    engine,
    options: {
      width,
      height,
      wireframes: false,
      background: "transparent",
    },
  })

  Render.setPixelRatio(render, "auto")

  const stemVertices = Vertices.scale(
    Vertices.fromPath(
      "332.922785357857 67.1426699293916 342.31463282363 23.0349708190033 316.021868914938 11.3043633824063 293.342197791752 6.0346476056402 261.816310094687 2.84605096974337 241.950339527712 0.61073400213081 209.78262825298 0 175.891579455691 0.73789876141473 140.065747534788 4.47382267020839 106.821113320978 5.00162000200089 88.9082768608941 6.87006372218184 47.045966864076 14.1440335260613 0 20.7328272095292 2.84198789512902 61.1241288315168 8.2256804763565 60.4282979769032 22.1799621506952 58.0029727195906 41.7467514893879 55.3355951156127 70.2389861701586 53.9784606223548 80.0239496656659 53.4340088932631 99.587394264855 49.1921503954072 127.499264204135 45.9209977788669 172.835880705646 46.9936921965264 192.116535201739 46.5232500443772 226.225606103416 46.5045059499089 238.603100460879 46.6967639965069 260.910574474777 46.8244945540109 295.033308360246 51.5376878358056 311.740198252925 56.64161283521"
    ),
    scale,
    scale
  )
  let leafVertices = Vertices.scale(
    Vertices.fromPath(
      "247.807479968773 36.0955106889678 244.893996193665 78.1952985014568 239.561608094977 101.810804104153 223.313011375758 196.674105614293 218.832567891071 211.719970167987 205.645369569191 240.318134440029 184.108171851802 294.549444447146 181.628551241864 302.99079032454 162.326037931281 334.698782340534 145.408829154709 354.964379089768 130.48664239672 374.266535554792 96.440692666034 406.101570749794 60.270090195826 441.158570406364 17.6558023992693 474.274847308483 7.07677348358993 447.192720844694 0.91490956833513 408.660685915385 0.28885751755661 364.783471045914 6.70705354324491 317.909669832583 6.99869509337805 296.878515133696 25.0800722155782 216.761657137472 44.1242060674449 166.066788048001 72.6406729148412 115.548239533236 105.151006204604 82.8230701572102 151.900630970465 40.4807237085788 195.365799434197 16.4950864066732 240.076575764559 0"
    ),
    scale,
    scale
  )

  // leaf = Vertices.rotate(leaf, 60, ["258.290089589566 89.8712769078273"])
  const grape = Vertices.scale(
    Vertices.fromPath(
      "161.218950216619 178.750023787093 134.781141031578 188.169687068718 131.889650705466 188.708520705554 83.9944412226278 189.930969891176 79.7874205703665 189.041045055853 40.9494257901242 170.77936474294 37.9858901674443 168.602253652908 20.7371400591155 149.913437071794 19.4321770348615 148.133538021232 11.6427422802235 134.556234002744 10.7390404485041 132.452748366571 0.43266202605628 98.2505400440004 0.00063714622956 95.2028610454809 0.20368735835018 76.5390800814739 1.08230419304709 72.5116541815196 9.95187521712614 52.6270535690992 11.7560198061492 49.8998008416047 35.9843740565702 23.9368783029577 38.5903003319299 21.9563486561292 70.7536613275852 5.29818233597325 73.4890123612058 4.37991342921123 98.3370845498857 0.13324070488488 100.387190497512 0.01270433139507 140.212191026753 2.05797104731209 154.731219411818 8.44941101708537 157.311270611507 10.2357788365653 178.360891567939 30.6324985515894 179.517813605827 31.5904195304174 182.245066333321 33.511100719782 185.578309382593 37.6153617680975 195.811852429829 61.0613406389348 196.41458367719 62.9091864530865 197.984914366799 69.9705143551637 199.805255568072 79.1595734318753 199.999417395421 81.2525411460683 199.675781429895 111.286541429882 199.588428359554 112.513039768565 197.80136922126 126.007039869774 197.125802598684 128.538550612627 192.147763947381 140.635296627702 191.489184015702 141.951617032498 183.976474306962 154.553223302612 183.375372596808 155.447197290999 171.556092326361 171.11914794080"
    ),
    scale,
    scale
  )

  const strem = Bodies.fromVertices(
    (width / 2) * 1.45,
    -radius * 2.8,
    stemVertices,
    {
      restitution: 0.5,
      render: {
        fillStyle: "#35c149",
        strokeStyle: "#35c149",
        lineWidth: 1,
      },
    }
  )
  let leaf = Bodies.fromVertices(
    (width / 2) * 1.35,
    -radius * 4.3,
    leafVertices,
    {
      restitution: 0.5,
      render: {
        fillStyle: "#35c149",
        strokeStyle: "#35c149",
        lineWidth: 1,
      },
    }
  )

  let grapes = [leaf, strem]
  // let grapes = []

  for (let i = 0; i < 10; i++) {
    const coords = grapesXY(width, radius, scale)
    grapes.push(
      Bodies.fromVertices(coords[i].x, coords[i].y, grape, {
        restitution: 0.5,
        scale: 0.5,
        render: {
          fillStyle: "#f2ad33",
          strokeStyle: "#f2ad33",
          lineWidth: 1,
        },
      })
    )
  }

  World.add(engine.world, grapes)

  World.add(engine.world, [
    // walls
    // left
    Bodies.rectangle(-10, height / 2, 20, height, { isStatic: true }),
    // right
    Bodies.rectangle(width + 10, height / 2, 20, height, { isStatic: true }),
    // bottom
    Bodies.rectangle(width / 2, height + 10, width, 20, { isStatic: true }),
  ])

  // add mouse control
  const mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    })

  World.add(engine.world, mouseConstraint)

  Engine.run(engine)

  Render.run(render)

  return {
    stop: function () {
      World.clear(engine.world)
      Engine.clear(engine)
      Render.stop(render)
      render.canvas.remove()
      render.canvas = null
      render.context = null
      render.textures = {}
    },
  }
}
