import React from "react"
import Animation from "../components/Animation"
import Grapes from "../components/Grapes"
import Layout from "../components/Layout"
import Background from "../components/Background"
import SEO from "../components/SEO"

const Home = () => (
  <Layout>
    <SEO title="Massif Wines" />
    <Grapes />
    <Animation />
    <Background />
  </Layout>
)

export default Home
