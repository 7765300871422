const grapesXY = (width, radius, scale) => {
  const middle = width / 2
  const alt = scale * 25
  return [
    { x: middle - radius * 3 + alt * 3, y: 0 },
    { x: middle - radius + alt, y: -20 },
    { x: middle + radius - alt, y: -40 },
    { x: middle + radius * 3 - alt * 3, y: -60 },
    { x: middle - radius - alt, y: -radius * 1.5 - 20 },
    { x: middle, y: -radius * 1.5 - 40 },
    { x: middle + radius + alt, y: -radius * 1.5 - 60 },
    { x: middle - radius / 2 - alt, y: -radius * 3 - 20 },
    { x: middle + radius / 2 + alt, y: -radius * 3 - 40 },
    { x: middle, y: -radius * 4.5 },
  ]
}

export default grapesXY
